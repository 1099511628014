@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset CSS */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #ff9900;
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--primary) !important;
  width: 24px;
  height: 24px;
}

.ant-menu-item:hover {
  background-color: var(--primary) !important;
  color: #fff !important;
}
.ant-menu-item.ant-menu-item-selected {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.ck-content {
  min-height: 400px;
}

.news-detail .ck-content {
  min-height: unset;
}

.news-detail .ck-content {
  border: none !important;
}

.ck-content p {
  margin: 14px 0;
}

.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content h4,
.ck-content h5,
.ck-content h6 {
  margin: 18px 0;
}

.news-detail .ck-sticky-panel__content {
  border: none !important;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 4px;
}

/* Custom scrollbar styles */
.modal-body::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

.main {
  display: flex;
  align-items: center;
  position: relative;
  background: linear-gradient(360deg, #ffefdd 0%, rgba(255, 255, 255, 0) 100%)
  no-repeat;
}

.logo {
  position: absolute;
  left: 0;
  width: 200px;
  margin: 15px;
}

.banner {
  width: 80%;
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: rgb(238, 231, 231);
}

.banner-login {
  width: 70%;
}
.right {
  width: 40%;
}

.sign-in-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
}
